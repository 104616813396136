import React, { Component } from 'react'
import Banner0 from './Banner0'
import Banner1 from './Banner1'
import Banner2 from './Banner2'

export default class BannerMain extends Component {
  render() {
    return (
      <div>
          <Banner0 />
          <Banner1 />
          <Banner2 />
      </div>
    )
  }
}
