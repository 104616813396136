import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack';
import React, { Component } from 'react'
import { queryTimings, setTiming, updateTiming } from '../CommonThings/ApiCall';

class Timings extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
            isLoading: true
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData() {
        queryTimings().then((value) => {
            var data = []
            value.forEach(element => {
                data.push({
                    id: element.id,
                    name: element.data().name,
                    date_created: element.data().timestamp,
                })
            })
            this.setState({
                isLoading: false,
                data: data,
            })
        }).catch((e) => {
            console.log(e)
            this.props.enqueueSnackbar('Something went wrong!', {
                variant: 'error'
            });
        })
    }

    postOps(newData) {
        if (newData.name) {
            setTiming(newData.name).then((value) => {
                this.fetchData()
            }).catch((error) => {
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
                console.log(error)
            })
        } else {
            this.fetchData()
            this.props.enqueueSnackbar('Fill all!', {
                variant: 'warning'
            });
        }
    }

    putOps(newData) {
        if (newData.id && newData.name) {
            updateTiming(newData.id, newData.name).then((value) => {
                this.fetchData()
            }).catch((error) => {
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
                console.log(error)
            })
        } else {
            this.fetchData()
            this.props.enqueueSnackbar('Fill all!', {
                variant: 'warning'
            });
        }
    }

    render() {
        return (
            <div>
                <MaterialTable
                    title="Timings"
                    columns={[
                        { title: 'Name', field: 'name' },
                        // { title: 'Timestamp', field: 'timestamp', type: 'date' },
                    ]}
                    isLoading={this.state.isLoading}
                    data={this.state.data}
                    options={{
                        paging: false
                    }}
                    editable={{

                        onRowAdd: newData =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    this.setState(prevState => {
                                        const data = [...prevState.data];
                                        data.push(newData);
                                        return { ...prevState, data };
                                    });
                                    this.postOps(newData);
                                }, 600);
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    if (oldData) {
                                        this.setState(prevState => {
                                            const data = [...prevState.data];
                                            data[data.indexOf(oldData)] = newData;
                                            return { ...prevState, data };
                                        });
                                        this.putOps(newData);
                                    }
                                }, 600);
                            }),

                    }}
                />
            </div>
        )
    }
}
export default withSnackbar(Timings)


