import React, { Component } from 'react';
import { firebaseConfig } from '../CommonThings/FirebaseConfig';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
var firebaseui = require('firebaseui');

firebase.initializeApp(firebaseConfig);

class Login extends Component {
  componentDidMount() {

    const uiConfig = {
      signInSuccessUrl: "/home", //This URL is used to return to that page when we got success response for phone authentication.
      signInOptions: [
        {
          provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
          // The default selected country.
          defaultCountry: 'IN'
        }
      ],
      tosUrl: "/home",
      privacyPolicyUrl: '<your-privacy-policy-url>',
    };
    var ui = new firebaseui.auth.AuthUI(firebase.auth());
    ui.start("#firebaseui-auth-container", uiConfig);
  }

  render() {
    return (
      <>
        <h1>Yuve Champions Academy</h1>
        <div id="firebaseui-auth-container"></div>
      </>
    )
  }
}

export default Login;