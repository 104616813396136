import React, { Component } from 'react'
import { getDocs, collection, query, where } from "firebase/firestore";
import { getFirestoreDb } from '../CommonThings/FirebaseConfig';
import { getCookie } from '../CommonThings/CookieHelper';
import MaterialTable from 'material-table';
import BillUpload from '../Storage/BillUpload';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import storage from '../Storage/firebase';
import { ref, getDownloadURL } from "firebase/storage";
import { setPayments } from '../CommonThings/ApiCall';
import { withSnackbar } from 'notistack';
import { Box, TextField } from '@material-ui/core';

const auth = JSON.parse(getCookie('access'))

class Payments extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            data: [],
            openBillUpload: false,
            billUploadData: null,
            openPayment: false,
            paymentMethod: '',
            cost: ''
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData() {
        if (this.props.data) {
            var data = []
            getPayments(this.props.data.id).then((value) => {
                value.forEach(element => {
                    console.log(element.data())
                    var dataTemp = element.data()
                    dataTemp.id = element.id
                    dataTemp.date_created = dataTemp.date_created.toDate()
                    data.push(dataTemp)
                })
                console.log(data)
                this.setState({
                    isLoading: false,
                    data,
                })
            }).catch((e) => {
                console.log(e)
                alert("Something went wrong")
            })
        } else {
            queryPayments().then((value) => {
                var data = []
                value.forEach(element => {
                    var dataTemp = element.data()
                    dataTemp.id = element.id
                    dataTemp.date_created = dataTemp.date_created.toDate()
                    data.push(dataTemp)
                })

                this.setState({
                    isLoading: false,
                    data,
                })
                console.log(data)
            }).catch((e) => {
                console.log(e)
                alert("Something went wrong")
            })
        }
    }

    downloadBill(rowData) {

        const starsRef = ref(storage, 'payments/invoices/' + rowData.id + '.pdf');

        // Get the download URL
        getDownloadURL(starsRef)
            .then((url) => {
                window.open(url)
                // Insert url into an <img> tag to "download"
            })
            .catch((error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/object-not-found':
                        alert("File doesn't exist")
                        // File doesn't exist
                        break;
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        alert("User doesn't have permission to access the object")
                        break;
                    case 'storage/canceled':
                        alert("User canceled the upload")
                        // User canceled the upload
                        break;
                    // ...
                    case 'storage/unknown':
                        alert("Unknown error occurred, inspect the server response")
                        // Unknown error occurred, inspect the server response
                        break;

                    default:
                        alert("Something went wrong!")
                }
            });
    }

    addPayment() {
        this.setState({ openPayment: true })

    }
    postPayment() {
        if (this.state.cost !== '') {
            setPayments(this.state.cost, this.state.paymentMethod, this.props.data).then((value) => {
                this.fetchData()
                this.props.enqueueSnackbar('Payment Added Successfully!', {
                    variant: 'success'
                });
                this.setState({ openPayment: false })
            }).catch((error) => {
                console.log(error)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
        } else {
            this.props.enqueueSnackbar('Please enter cost!', {
                variant: 'warning'
            });
        }

    }

    render() {

        const handleClose = () => {
            this.setState({
                openBillUpload: false
            })
        };

        const handlePaymentClose = () => {
            this.setState({
                openPayment: false
            })
        };

        const handleChange = (e) => {
            if (e.target.id === 'cost') {
                this.setState({ cost: e.target.value })
            } else if (e.target.id === 'payment_method') {
                this.setState({ paymentMethod: e.target.value })
            }
        }

        return (
            <div>
                <MaterialTable
                    title={this.props.data ? "Payments" : "One Detail Panel Preview"}
                    columns={[
                        { title: 'Yuve Txn Id', field: 'id' },
                        { title: 'Phone Number', field: 'phone_number' },
                        { title: 'Profile ID', field: 'profile_id' },
                        { title: 'Profile Name', field: 'profile_name' },
                        { title: 'Plan Name', field: 'plan_name' },
                        { title: 'Amount', field: 'cost' },
                        { title: 'Time of payment (Approx)', field: 'date_created', type: 'datetime' },
                        { title: 'Txn ID', field: 'txnId' },
                        { title: 'Txn Ref', field: 'txnRef' },
                        { title: 'Payment Method', field: 'payment_method' },
                        { title: 'Approval Ref', field: 'approvalRef' },
                        { title: 'Status (S/F)', field: 'resCode' },
                    ]}
                    isLoading={this.state.isLoading}
                    data={this.state.data}
                    options={{
                        padding: "dense",
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 50],
                        paging: this.props.data ? true : false,
                    }}
                    actions={[
                        rowData => ({
                            icon: 'upload',
                            tooltip: 'Bill Upload',
                            onClick: (event, rowData) => this.setState({ openBillUpload: true, billUploadData: rowData }),
                        }),
                        rowData => ({
                            icon: 'download',
                            tooltip: 'Bill Download',
                            onClick: (event, rowData) => this.downloadBill(rowData),
                        }),
                        this.props.data ? {
                            icon: 'add',
                            tooltip: 'Add Payment',
                            isFreeAction: true,
                            // disabled: this.state.data.length > 0,
                            onClick: (event, rowData) => this.addPayment(rowData)
                        } : null
                    ]}
                // editable={(this.props.data) ? ({
                //     onRowAdd: newData =>
                //         new Promise(resolve => {
                //             setTimeout(() => {
                //                 resolve();
                //                 this.setState(prevState => {
                //                     const data = [...prevState.data];
                //                     this.addPayment(newData);
                //                     data.push(newData);
                //                     return { ...prevState, data };
                //                 });
                //             }, 600);
                //         }),
                // }) : null}
                />
                <Dialog open={this.state.openBillUpload} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Bill Upload</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Choose the bill from local machine and upload. Wait for "success" message.
                        </DialogContentText>
                        <BillUpload data={this.state.billUploadData} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>

                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.openPayment} onClose={handlePaymentClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Add Payment</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please enter the informations correctly
                        </DialogContentText>
                        <Box display='flex' flexDirection='column'>
                            <Box width='100%'>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="cost"
                                    label="Cost"
                                    value={this.state.cost}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Box>

                            {/* <FormControl style={{ width: '100%', marginTop: '5px' }}>
                                <InputLabel id="demo-simple-select-label">Category</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={this.state.profileCategory}
                                    onChange={handleProfileCategoryChange}
                                    fullWidth
                                >
                                    <MenuItem value={0}>Kids</MenuItem>
                                    <MenuItem value={1}>Adults</MenuItem>
                                    <MenuItem value={2}>Womens</MenuItem>
                                </Select>
                            </FormControl> */}

                            <Box width='100%' mt={1}>
                                <TextField
                                    margin="dense"
                                    id="payment_method"
                                    label="Payment Method"
                                    value={this.state.paymentMethod}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Box>

                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handlePaymentClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => this.postPayment()} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}
export default withSnackbar(Payments)

async function queryPayments() {
    // console.log(auth)
    if (auth) {
        const db = getFirestoreDb();
        const querySnapshot = await getDocs(collection(db, "Invoices"));
        return querySnapshot
    }
}

async function getPayments(id) {
    const db = getFirestoreDb();
    const q = query(collection(db, "Invoices"), where("profile_id", "==", id));
    const querySnapshot = await getDocs(q);
    return querySnapshot
}