import { withSnackbar } from 'notistack'
import { Box, Button, CircularProgress, IconButton, TextField } from '@material-ui/core'
import React, { Component } from 'react'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { checkExists, DTVExists, queryProfiles, queryStudents, queryTimings, queryVenue, STVExists, SVExists, VDExists } from '../CommonThings/ApiCall'
import MaterialTable from 'material-table';
import Autocomplete from '@material-ui/lab/Autocomplete';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Tooltip } from '@mui/material';
import { deleteDoc, doc } from 'firebase/firestore';
import { getFirestoreDb } from '../CommonThings/FirebaseConfig';

class ViewAttendance extends Component {
    constructor(props) {
        super(props)

        this.state = {
            venues: [],
            timings: [],
            students: [],
            timing: '',
            venue: '',
            isLoading: true,
            isLoading1: true,
            isLoading2: true,
            date: null,
            data: [],
            selectedStudents: [],
            inputLoading: false
        }
    }

    componentDidMount() {
        this.getTiming()
        this.getVenues()
        this.getStudents()
    }

    getVenues() {
        queryVenue().then((value) => {
            var data = []
            value.forEach(element => {
                data.push({
                    id: element.id,
                    name: element.data().name,
                    receiver_name: element.data().receiver_name,
                    upi_id: element.data().upi_id,
                    merchant_id: element.data().merchant_id,
                })
            })
            this.setState({
                isLoading1: false,
                venues: data,
            })
        }).catch((e) => {
            console.log(e)
            alert("Something went wrong")
        })
    }

    getTiming() {
        queryTimings().then((value) => {
            var timings = []
            value.forEach(element => {
                timings.push({
                    id: element.id,
                    name: element.data().name,
                    date_created: element.data().timestamp,
                })
            })
            this.setState({
                isLoading: false,
                timings: timings,
            })
        }).catch((e) => {
            console.log(e)
            alert("Something went wrong")
        })
    }

    getStudents() {
        queryStudents().then((value) => {
            var data = []
            value.forEach(element => {
                data.push({
                    id: element.id,
                    active: element.data().active
                })
            })
            this.fetchProfiles(data)
        }).catch(() => {
            this.props.enqueueSnackbar('Something went wrong!', {
                variant: 'error'
            });
        })
    }

    fetchProfiles(data) {
        var data1 = []
        var profileLength = 0
        for (let i = 0; i < data.length; i++) {
            const uservalue = data[i];
            queryProfiles(uservalue.id).then((profile) => {
                profileLength += profile.size
                profile.forEach(profilevalue => {
                    data1.push({
                        id: profilevalue.id,
                        name: profilevalue.data().name,
                        category: profilevalue.data().category,
                        reg_phone: uservalue.id,
                    })
                })
            }).catch((error) => {
                console.log(error)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
        }
        const timeout = setInterval(() => {
            if (profileLength && profileLength === data1.length) {
                this.updateData(data1)
                clearInterval(timeout)
            }
        }, 200);
    }

    updateData(data1) {
        this.setState({ isLoading2: false, students: data1 })
    }

    submitData() {
        var fields = ''
        var optional = []

        if (!this.state.venue) {
            fields += "Venue";
        }

        if (!this.state.date && this.state.selectedStudents.length === 0) {
            optional.push('Date', 'Students')
        } else if (!this.state.date) {
            optional.push('Date')
        } else if (this.state.selectedStudents.length === 0) {
            optional.push('Students')
        }

        if (fields && optional.length === 2) {
            this.props.enqueueSnackbar(fields + " is required! You have select either date or students", {
                variant: 'warning'
            });
        } else if (!fields && optional.length === 2) {
            this.props.enqueueSnackbar('You have select either date or students', {
                variant: 'warning'
            });
        } else if ((fields && optional.length !== 0) || (fields && optional.length === 0)) {
            this.props.enqueueSnackbar(fields + " is required! ", {
                variant: 'warning'
            });
        } else if ((this.state.date !== null) && (this.state.timing !== '') && (this.state.venue !== '') && (this.state.selectedStudents.length !== 0)) {
            this.props.enqueueSnackbar('You have selected all', {
                variant: 'success'
            });
            this.setState({ isLoading2: true })
            var profileLength = 0
            let data = []
            for (let i = 0; i < this.state.selectedStudents.length; i++) {
                const uservalue = this.state.selectedStudents[i];
                console.log(this.state.selectedStudents)
                checkExists(uservalue, this.state.date, this.state.timing, this.state.venue).then((profile) => {
                    profile.forEach(profilevalue => {
                        data.push({
                            id: profilevalue.id,
                            name: profilevalue.data().name,
                            category: profilevalue.data().category,
                            reg_phone: profilevalue.data().reg_phone,
                            date: profilevalue.data().date_created,
                        })
                        // console.log(data)
                    })
                    profileLength = profileLength + 1
                }).catch((error) => {
                    console.log(error)
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                })
                const timeout = setInterval(() => {
                    if (profileLength && profileLength === this.state.selectedStudents.length) {
                        this.setState({ data, isLoading2: false })
                        clearInterval(timeout)
                    }
                }, 200);
            }

        } else if ((this.state.date !== null) && (this.state.timing !== '') && (this.state.venue !== '')) {
            this.props.enqueueSnackbar('You have selected date, time and venue', {
                variant: 'success'
            });
            this.setState({ isLoading2: true })
            let data = []
            DTVExists(this.state.date, this.state.timing, this.state.venue).then((profile) => {
                profile.forEach(profilevalue => {
                    data.push({
                        id: profilevalue.id,
                        name: profilevalue.data().name,
                        category: profilevalue.data().category,
                        reg_phone: profilevalue.data().reg_phone,
                    })
                })
                this.setState({ data, isLoading2: false })
            }).catch((error) => {
                console.log(error)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
        } else if ((this.state.timing !== '') && (this.state.venue !== '') && (this.state.selectedStudents.length !== 0)) {
            this.props.enqueueSnackbar('You have selected students, time and venue', {
                variant: 'success'
            });
            this.setState({ isLoading2: true })
            let profileLength = 0
            let data = []
            for (let i = 0; i < this.state.selectedStudents.length; i++) {
                const uservalue = this.state.selectedStudents[i];
                STVExists(uservalue, this.state.timing, this.state.venue).then((profile) => {
                    profile.forEach(profilevalue => {
                        data.push({
                            id: profilevalue.id,
                            name: profilevalue.data().name,
                            category: profilevalue.data().category,
                            reg_phone: profilevalue.data().reg_phone,
                        })
                    })
                    profileLength = profileLength + 1
                }).catch((error) => {
                    console.log(error)
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                })
                const timeout = setInterval(() => {
                    if (profileLength && profileLength === this.state.selectedStudents.length) {
                        this.setState({ data, isLoading2: false })
                        clearInterval(timeout)
                    }
                }, 200);
            }
        } else if (!fields && optional.length === 1 && optional[0] === 'Date') {
            this.props.enqueueSnackbar('You have venue and students', {
                variant: 'success'
            });
            this.setState({ isLoading2: true })
            let profileLength = 0
            let data = []
            for (let i = 0; i < this.state.selectedStudents.length; i++) {
                const uservalue = this.state.selectedStudents[i];
                SVExists(uservalue, this.state.venue).then((profile) => {
                    profile.forEach(profilevalue => {
                        data.push({
                            id: profilevalue.id,
                            name: profilevalue.data().name,
                            category: profilevalue.data().category,
                            reg_phone: profilevalue.data().reg_phone,
                        })
                    })
                    profileLength = profileLength + 1
                }).catch((error) => {
                    console.log(error)
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                })
                const timeout = setInterval(() => {
                    if (profileLength && profileLength === this.state.selectedStudents.length) {
                        this.setState({ data, isLoading2: false })
                        clearInterval(timeout)
                    }
                }, 200);
            }
        } else if (!fields && optional.length === 1 && optional[0] === 'Students') {
            this.props.enqueueSnackbar('You have select venue and date', {
                variant: 'success'
            });
            this.setState({ isLoading2: true })
            let data = []
            VDExists(this.state.date, this.state.venue).then((profile) => {
                profile.forEach(profilevalue => {
                    data.push({
                        id: profilevalue.id,
                        name: profilevalue.data().name,
                        category: profilevalue.data().category,
                        reg_phone: profilevalue.data().reg_phone,
                    })
                })
                this.setState({ data, isLoading2: false })
            }).catch((error) => {
                console.log(error)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
        }
    }

    selectStudents(value) {
        this.setState({ selectedStudents: value })
    }

    deleteData(data) {
        const db = getFirestoreDb();
        deleteDoc(doc(db, "Attendance/", data.id)).then((value) => {
            // this.fetchData()
            this.props.enqueueSnackbar('Deleted Successfully!', {
                variant: 'success'
            });
        }).catch((error) => {
            // this.setState({ isLoading: false })
            // this.fetchData()
            this.props.enqueueSnackbar('Something went wrong!', {
                variant: 'error'
            });
        })
    }

    render() {

        const handleTimingChange = (event) => {
            this.setState({
                timing: event.target.value
            })
        };

        const handleVenueChange = (event) => {
            this.setState({
                venue: event.target.value
            })
        };

        const handleDateChange = (date) => {
            this.setState({
                date: date,
            })
        };


        return (
            <div>
                {(this.state.isLoading || this.state.isLoading1) ? <Box display='flex' flexDirection='row' justifyContent={'center'} alignItems='center'><CircularProgress /></Box> :
                    <div>{
                        this.state.inputLoading ?
                            <Box display='flex' flexDirection='row' justifyContent={'center'} alignItems='center' marginTop='20px'><CircularProgress /></Box> :
                            <Box display='flex' flexDirection='row' justifyContent={'center'} alignItems='center' marginTop='20px'>
                                <Box marginRight={'30px'} width={'100%'}>
                                    <Autocomplete
                                        multiple
                                        id="tags-outlined"
                                        options={this.state.students}
                                        disableCloseOnSelect
                                        onChange={(e, value) => this.selectStudents(value)}
                                        getOptionLabel={(option) => option.name}
                                        filterSelectedOptions
                                        renderInput={(params) => (
                                            <TextField
                                                style={{ width: '100%' }}
                                                {...params}
                                                variant="outlined"
                                                label="Select Students"
                                                placeholder="Select Students"
                                            />
                                        )}
                                    />
                                </Box>

                                <Box marginRight={'30px'}>
                                    <FormControl style={{ width: '250px' }}>
                                        <InputLabel id="demo-simple-select-label">Venue</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={this.state.venue}
                                            onChange={handleVenueChange}
                                            fullWidth
                                        >
                                            {this.state.venues.map((ele, index) => <MenuItem key={index} value={ele.id}>{ele.name}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box marginRight={'30px'} >
                                    <FormControl style={{ width: '250px' }}>
                                        <InputLabel id="demo-simple-select-label">Timing</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={this.state.timing}
                                            onChange={handleTimingChange}
                                            fullWidth
                                        >
                                            {this.state.timings.map((ele, index) => <MenuItem key={index} value={ele.id}>{ele.name}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box marginRight={'30px'}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="date-picker-dialog"
                                            label="Date"
                                            format="MM/dd/yyyy"
                                            value={this.state.date}
                                            onChange={handleDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            style={{ width: 250 }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Box>
                                <Button variant="contained" color="primary"
                                    onClick={() => this.submitData()}
                                >
                                    Submit
                                </Button>

                                <IconButton color="primary" size='medium'
                                    onClick={() => this.setState({ inputLoading: true, selectedStudents: [], timing: '', venue: '', date: null }, () => { this.setState({ inputLoading: false }) })}
                                >
                                    {/* <Tooltip title={'Clear Filter'}> */}
                                    <RefreshIcon fontSize='small' />
                                    {/* </Tooltip> */}
                                </IconButton>

                            </Box>
                    }
                        <Box>
                            <MaterialTable
                                title=""
                                columns={[
                                    {
                                        title: 'Name', field: 'name',
                                    },
                                    {
                                        title: 'Category', field: 'category',
                                    },
                                    {
                                        title: 'Date', field: 'date',
                                    },
                                    {
                                        title: 'Phone', field: 'reg_phone',
                                    },

                                ]}
                                data={this.state.data}
                                isLoading={this.state.isLoading2}
                                style={{ margin: '10px' }}
                                options={{
                                    padding: "dense",
                                    actionsColumnIndex: -1,
                                    paging: false
                                }}
                                editable={{
                                    onRowDelete: oldData =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                this.setState(prevState => {
                                                    const data = [...prevState.data];
                                                    data.splice(data.indexOf(oldData), 1);
                                                    return { ...prevState, data };
                                                });
                                                this.deleteData(oldData)
                                            }, 600);
                                        }),
                                }}
                            />
                        </Box>
                    </div>
                }
            </div>
        )
    }
}
export default withSnackbar(ViewAttendance)