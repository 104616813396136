import MaterialTable from 'material-table'
import React, { Component } from 'react'
import { getDocs, collection } from "firebase/firestore";
import { getFirestoreDb } from '../CommonThings/FirebaseConfig';
import { getCookie } from '../CommonThings/CookieHelper';
import { withSnackbar } from 'notistack';
import { queryPlan, queryProfiles, queryTimings, queryVenue } from '../CommonThings/ApiCall';
import Payments from '../Payments/Payments';
import moment from 'moment';
import { CsvBuilder } from 'filefy';

const auth = JSON.parse(getCookie('access'))

class StudentLists extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            data: [],
            venues: [],
            venue: 'all',
            timings: []
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData() {
        queryUsers().then((value) => {
            var data = []
            value.forEach(element => {
                data.push({
                    id: element.id,
                    active: element.data().active
                })
            })
            this.fetchProfiles(data)
        }).catch(() => {
            this.props.enqueueSnackbar('Something went wrong!', {
                variant: 'error'
            });
        })

        queryVenue().then((value) => {
            var venues = {}
            value.forEach(element => {
                venues[element.id] = element.data().name
            })
            this.setState({
                venues: venues,
                isLoading: false
            })
        }).catch((error) => {
            alert(error)
        })

        queryTimings().then((value) => {
            var timings = {}
            value.forEach(element => {
                timings[element.id] = element.data().name
            })
            this.setState({
                timings: timings,
                isLoading: false
            })
        }).catch((error) => {
            alert(error)
        })
    }

    fetchProfiles(data) {
        var data1 = []
        var profileLength = 0
        for (let i = 0; i < data.length; i++) {
            const uservalue = data[i];
            queryProfiles(uservalue.id).then((profile) => {
                profileLength += profile.size
                profile.forEach(profilevalue => {
                    var profileObj = {
                        id: profilevalue.id,
                        name: profilevalue.data().name,
                        category: profilevalue.data().category,
                        reg_phone: uservalue.id,
                    }
                    this.fetchPlan(uservalue, profilevalue, profileObj, data1)
                })

            }).catch((error) => {
                console.log(error)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
        }
        const timeout = setInterval(() => {
            if (profileLength && profileLength === data1.length) {
                this.updateData(data1)
                clearInterval(timeout)
            }
        }, 200);
    }

    fetchPlan(uservalue, profilevalue, profileObj, data1) {
        queryPlan(uservalue.id, profilevalue.id).then((plan) => {
            plan.forEach(planvalue => {
                if (planvalue.id === "TrainingPlan") {
                    profileObj.PlanCost = planvalue.data().cost
                    profileObj.planName = planvalue.data().name
                    profileObj.planTiming = planvalue.data().timing ? planvalue.data().timing.id : null
                    profileObj.venue = planvalue.data().venue ? planvalue.data().venue.id : null
                    profileObj.planEndDate = planvalue.data().end_date.toDate()
                    profileObj.planStartDate = planvalue.data().start_date.toDate()
                }
            })
            data1.push(profileObj)
        }).catch((error) => {
            console.log(error)
            this.props.enqueueSnackbar('Something went wrong!', {
                variant: 'error'
            });
        })
    }

    updateData(data1) {
        this.setState({ isLoading: false, data: data1 })
    }

    render() {
        const exportCsv = (columnList, initialData) => {
            const columns = columnList.filter(columnDef => {
                return !columnDef.hidden && columnDef.field && columnDef.export !== false;
            });

            const data = initialData.map(rowData =>
                columns.map(columnDef => {
                    return columnDef.render ? columnDef.render(rowData) : columnDef.lookup ? columnDef.lookup[rowData[columnDef.field]] : rowData[columnDef.field];
                })
            );

            const builder = new CsvBuilder("Students " + moment().format('MM/DD/YYYY hh:mm') + '.csv');
            builder
                .setDelimeter(',')
                .setColumns(columns.map(columnDef => columnDef.title))
                .addRows(data)
                .exportFile();
        };
        return (
            <div>
                <MaterialTable
                    title="Students List"
                    columns={[
                        { title: 'Name', field: 'name' },
                        {
                            title: 'Registered Phone Number', field: 'reg_phone',
                            render: rowData => rowData.reg_phone.toString()
                        },
                        {
                            title: "Date of Joining", field: 'planStartDate',
                            render: rowData => moment(rowData.planStartDate).format('DD-MM-YYYY')
                        },
                        { title: "Category", field: 'category', lookup: { 0: 'Kids', 1: 'Adults', 2: 'Womens' } },
                        { title: "Plan Timing", field: 'planTiming', lookup: this.state.timings },
                        {
                            title: 'Fees Paid Upto', field: 'planEndDate',
                            render: rowData => moment(rowData.planEndDate).format('DD-MM-YYYY')
                        },
                        { title: 'Amount', field: 'PlanCost' },
                        { title: 'Venue', field: 'venue', lookup: this.state.venues },
                    ]}
                    isLoading={this.state.isLoading}
                    data={this.state.data}
                    detailPanel={rowData => {
                        return (
                            <Payments data={rowData} />
                        )
                    }}
                    options={{
                        paging: false,
                        filtering: true,
                        exportButton: { csv: true },
                        exportCsv: (columns, data) => {
                            exportCsv(columns, data)
                        },
                    }}
                />

            </div>
        )
    }
}

export default withSnackbar(StudentLists)


async function queryUsers() {
    if (auth) {
        const db = getFirestoreDb();
        const querySnapshot = await getDocs(collection(db, "Users"));
        return querySnapshot
    }
}

// queryUsers().then((user) => {
//     var data = []
//     for (let i = 0; i < user.length; i++) {
//         const uservalue = user[i];
//         console.log(uservalue)
//         queryProfiles(uservalue.id).then((profile) => {
//             for (let j = 0; j < profile.length; j++) {
//                 const profilevalue = profile[j];
//                 queryPlan(uservalue.id, profilevalue.id).then((plan) => {
//                     for (let k = 0; k < plan.length; k++) {
//                         const planvalue = plan[k];
//                         data.push({
//                             id: profilevalue.id,
//                             // doj: moment(profilevalue.data().dob.toDate()).format('DD-MM-YYYY'),
//                             name: profilevalue.data().name,
//                             category: profilevalue.data().category,
//                             phno: profilevalue.data().phno,
//                             planName: planvalue.data().name,
//                             PlanCost: parseInt(planvalue.data().cost),
//                             venue: planvalue.data().venue ? planvalue.data().venue.id : null,
//                             planStartDate: moment(planvalue.data().start_date.toDate()).format('DD-MM-YYYY'),
//                             planEndDate: moment(planvalue.data().end_date.toDate()).format('DD-MM-YYYY'),
//                         })
//                         console.log(data)
//                         this.setState({
//                             isLoading: false,
//                             data,
//                         })
//                     }
//                 }).catch((error) => {
//                     this.props.enqueueSnackbar('Something went wrong!', {
//                         variant: 'error'
//                     });
//                 })
//             }
//         }).catch((error) => {
//             this.props.enqueueSnackbar('Something went wrong!', {
//                 variant: 'error'
//             });
//         })
//     }
// }).catch(() => {
//     this.props.enqueueSnackbar('Something went wrong!', {
//         variant: 'error'
//     });
// })