import { initializeApp } from "firebase/app";
import { firebaseConfig } from '../CommonThings/FirebaseConfig';
import { getStorage } from "firebase/storage";

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export default storage;


// // import {initializeApp} from 'firebase/firebase-app';
// // import {getStorage} from 'firebase/firebase-storage';
// // import { firebaseConfig } from '../CommonThings/FirebaseConfig';
// // initializeApp(firebaseConfig);
// // var storage = getStorage();
// // export default storage;

// import firebase from 'firebase';
// import { firebaseConfig } from '../CommonThings/FirebaseConfig';
// firebase.initializeApp(firebaseConfig);
// var storage = firebase.storage();
// export default storage;