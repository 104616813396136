import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core';

const bbColorTheme = createTheme({
  palette: {
    primary: {
      main: '#DD7C0D',
    },
    secondary: {
      main: '#0091B5',
    },
 
  }
})

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={bbColorTheme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
