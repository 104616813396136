
import React, { Component } from 'react'
import { withSnackbar } from 'notistack';
import { AppBar, Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import BookAttendance from './BookAttendance';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ViewAttendance from './ViewAttendance';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}
class Attendance extends Component {
    constructor(props) {
        super(props)

        this.state = {
            value: 0
        }
    }

    render() {

        const handleChange = (event, newValue) => {
            this.setState({ value: newValue });
        };

        return (
            <div>
                <AppBar position='static' color='secondary'>
                    <Tabs value={this.state.value} style={{ marginTop: '5px', marginLeft: '8px', marginRight: '8px' }} onChange={handleChange} aria-label="simple tabs example"
                        indicatorColor="primary"
                        variant="fullWidth"
                        scrollButtons="auto"
                    >
                        <Tab label="Book Attendance" {...a11yProps(0)} />
                        <Tab label="View Attendance" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={this.state.value} index={0}>
                    <BookAttendance />
                </TabPanel>
                <TabPanel value={this.state.value} index={1}>
                    <ViewAttendance />
                </TabPanel>
            </div>
        )
    }
}
export default withSnackbar(Attendance)



