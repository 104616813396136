import MaterialTable from 'material-table'
import React, { Component } from 'react'
import { getFirestoreDb } from '../CommonThings/FirebaseConfig';
import {  collection, setDoc, doc, addDoc } from "firebase/firestore";
// import { getCookie } from '../CommonThings/CookieHelper';
import { queryVenue } from '../CommonThings/ApiCall';

// const auth = JSON.parse(getCookie('access'))

class Venue extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            isLoading: true
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData() {
        queryVenue().then((value) => {
            var data = []
            value.forEach(element => {
                // console.log(element.data().timestamp.toDate())
                data.push({
                    id: element.id,
                    name: element.data().name,
                    receiver_name: element.data().receiver_name,
                    upi_id: element.data().upi_id,
                    merchant_id: element.data().merchant_id,
                    // timestamp: element.data().timestamp.toDate()
                })
            })
            this.setState({
                isLoading: false,
                data: data,
            })
        }).catch((e) => {
            console.log(e)
            alert("Something went wrong")
        })
    }

    postOps(newData) {
        if (newData.name && newData.receiver_name && newData.upi_id && newData.merchant_id) {
            setTweet(newData.name, newData.receiver_name, newData.upi_id, newData.merchant_id).then((value) => {
                this.fetchData()
            }).catch((error) => {
                alert(error)
            })
        } else {
            alert("Fill all!")
        }
    }

    putOps(newData) {
        if (newData.id && newData.name && newData.receiver_name && newData.upi_id && newData.merchant_id) {
            setPlan(newData.id, newData.name, newData.receiver_name, newData.upi_id, newData.merchant_id).then((value) => {

                this.fetchData()
            }).catch((error) => {
                alert(error)
            })
        } else {
            alert("Fill all!")
        }
    }

    // deleteProfile(data) {
    //     console.log(data)
    //     const db = getFirestoreDb();
    //     deleteDoc(doc(db, "tweet/", data.id)).then((value) => {
    //         // alert("deleted")
    //         this.fetchData()
    //     }).catch((error) => {
    //         alert(error)
    //     })
    // }

    render() {
        return (
            <div>
                <MaterialTable
                    title="Venues"
                    columns={[
                        { title: 'Name', field: 'name' },
                        { title: 'Receiver Name', field: 'receiver_name' },
                        { title: 'UPI ID', field: 'upi_id' },
                        { title: 'Merchant ID', field: 'merchant_id' },
                        // { title: 'Timestamp', field: 'timestamp', type: 'date' },

                    ]}
                    isLoading={this.state.isLoading}
                    data={this.state.data}
                    options={{
                        paging: false
                    }}
                    editable={{
                        onRowAdd: newData =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    this.setState(prevState => {
                                        const data = [...prevState.data];
                                        data.push(newData);
                                        return { ...prevState, data };
                                    });
                                    this.postOps(newData);
                                }, 600);
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    if (oldData) {
                                        this.setState(prevState => {
                                            const data = [...prevState.data];
                                            data[data.indexOf(oldData)] = newData;
                                            return { ...prevState, data };
                                        });
                                        this.putOps(newData);
                                    }
                                }, 600);
                            }),
                    }}
                />
            </div>
        )
    }
}

async function setTweet(name, receiver_name, upi_id, merchant_id) {
    const db = getFirestoreDb();
    await addDoc(collection(db, "Venues"), {
        name: name,
        receiver_name: receiver_name,
        upi_id: upi_id,
        merchant_id: merchant_id,
        timestamp: new Date()
    });
}

async function setPlan(id, name, receiver_name, upi_id, merchant_id) {
    const db = getFirestoreDb();
    await setDoc(doc(db, "Venues/", id), {
        name: name,
        receiver_name: receiver_name,
        upi_id: upi_id,
        merchant_id: merchant_id,
        timestamp: new Date()
    });
}

export default Venue;