import MaterialTable from 'material-table'
import React, { Component } from 'react'
import { getFirestoreDb} from '../CommonThings/FirebaseConfig';
import { getDocs, collection, doc, addDoc, deleteDoc } from "firebase/firestore";
import { getCookie } from '../CommonThings/CookieHelper';

const auth = JSON.parse(getCookie('access'))

class Banner0 extends Component {

    constructor(props) {
      super(props)
    
      this.state = {
         data: [],
         isLoading: true
      }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData() {
        queryUsers().then((value) => {
            var data = []
            value.forEach(element => {
                // console.log(element.data().timestamp.toDate())
                data.push({
                    id: element.id,
                    url: element.data().url,
                    // timestamp: element.data().timestamp.toDate()
                })
            })
            this.setState({
                isLoading: false,
                data: data,
            })
        }).catch((e) => {
            console.log(e)
            alert("Something went wrong")
        })
    }

    postOps(newData) {
        if (newData.tweet) {
            setTweet(newData.tweet).then((value) => {

                this.fetchData()
            }).catch((error) => {
                alert(error)
            })
        } else {
            alert("Fill all!")
        }
    }

    deleteProfile(data) {
        console.log(data)
        const db = getFirestoreDb();
        deleteDoc(doc(db, "tweet/" , data.id)).then((value) => {
            // alert("deleted")
            this.fetchData()
        }).catch((error) => {
            alert(error)
        })
    }

    render() {
        return (
            <div>
                <MaterialTable
                    title="Banner0"
                    columns={[
                        { title: 'URL', field: 'url' },
                        // { title: 'Timestamp', field: 'timestamp', type: 'date' },
                       
                    ]}
                    isLoading={this.state.isLoading}
                    data={this.state.data}
                    options={{
                        paging: false
                    }}
                    actions={[
                        
                        {
                            icon: 'delete',
                            tooltip: 'Delete',
                            onClick: (event, rowData) => {
                                this.deleteProfile(rowData)
                                this.setState({ isLoading: true })
                            }
                        },
                       
                    ]}
                    editable={{
                       
                        onRowAdd: newData =>
                        new Promise(resolve => {

                            setTimeout(() => {
                                resolve();
                                this.setState(prevState => {
                                    const data = [...prevState.data];
                                    this.postOps(newData);
                                    data.push(newData);
                                    // this.postData(newData)
                                    return { ...prevState, data };
                                });
                            }, 600);
                        }),
                       
                    }}
                />
            </div>
        )
    }
}

async function queryUsers() {
    if (auth) {
        const db = getFirestoreDb();
        const querySnapshot = await getDocs(collection(db, "Banner0"));
        return querySnapshot
    }
}

async function setTweet(url) {
    const db = getFirestoreDb();
    await addDoc(collection(db, "Banner0"), {
        url: url,
        timestamp: new Date()
    });
}


export default Banner0;