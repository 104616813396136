var firestoredb = null
export function getFirestoreDb() {
    return firestoredb
}

export function setFirestoreDb(db) {
    firestoredb = db
}

var firebase = null
export function getFirebase() {
    return firebase
}

export function setFirebase(db) {
    firebase = db
}

var user = null
export function getUser() {
    return user
}

export function setUser(db) {
    user = db
}

var auth = null
export function getAuth() {
    return auth
}

export function setAuth(db) {
    auth = db
}

export const firebaseConfig = {
    apiKey: "AIzaSyBX-Apr_EgncuXSKI6XposRh5QeZEQ39cU",
    authDomain: "yuve-coachingapp.firebaseapp.com",
    projectId: "yuve-coachingapp",
    storageBucket: "yuve-coachingapp.appspot.com",
    messagingSenderId: "388870360947",
    appId: "1:388870360947:web:81714ce06de822e53f3f06",
    measurementId: "G-4T984DLJFE"
  };