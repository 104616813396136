import { Box, Button, CircularProgress, List, ListItemText } from '@material-ui/core'
import React, { Component } from 'react'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { checkExists, queryPlan, queryProfiles, queryStudents, queryTimings, queryVenue, setAttendance } from '../CommonThings/ApiCall';
import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class BookAttendance extends Component {
    constructor(props) {
        super(props)

        this.state = {
            venues: [],
            timings: [],
            students: [],
            timing: '',
            venue: '',
            submitLists: [],
            isLoading: true,
            isLoading1: true,
            isLoading2: true,
            date: new Date(),
            alreadyExists: [],
            openAlreadyExists: false,
            lookupVenues: []
        }
    }

    componentDidMount() {
        queryVenue().then((value) => {
            var venues = {}
            value.forEach(element => {
                venues[element.id] = element.data().name
            })
            this.setState({
                lookupVenues: venues,
            })
        }).catch((error) => {
            alert(error)
        })
        this.getTiming()
        this.getVenues()
        this.getStudents()
    }

    getVenues() {
        queryVenue().then((value) => {
            var data = []
            value.forEach(element => {
                data.push({
                    id: element.id,
                    name: element.data().name,
                    receiver_name: element.data().receiver_name,
                    upi_id: element.data().upi_id,
                    merchant_id: element.data().merchant_id,
                })
            })
            this.setState({
                isLoading1: false,
                venues: data,
            })
        }).catch((e) => {
            console.log(e)
            this.props.enqueueSnackbar('Something went wrong!', {
                variant: 'error'
            });
        })
    }

    getTiming() {
        queryTimings().then((value) => {
            var timings = []
            value.forEach(element => {
                timings.push({
                    id: element.id,
                    name: element.data().name,
                    date_created: element.data().timestamp,
                })
            })
            this.setState({
                isLoading: false,
                timings: timings,
            })
        }).catch((e) => {
            console.log(e)
            this.props.enqueueSnackbar('Something went wrong!', {
                variant: 'error'
            });
        })
    }

    getStudents() {
        queryStudents().then((value) => {
            var data = []
            value.forEach(element => {
                data.push({
                    id: element.id,
                    active: element.data().active
                })
            })
            this.fetchProfiles(data)
        }).catch(() => {
            this.props.enqueueSnackbar('Something went wrong!', {
                variant: 'error'
            });
        })
    }

    fetchProfiles(data) {
        var data1 = []
        var profileLength = 0
        for (let i = 0; i < data.length; i++) {
            const uservalue = data[i];
            queryProfiles(uservalue.id).then((profile) => {
                profileLength += profile.size
                profile.forEach(profilevalue => {
                    var profileObj = {
                        id: profilevalue.id,
                        name: profilevalue.data().name,
                        category: profilevalue.data().category,
                        reg_phone: uservalue.id,
                    }
                    this.fetchPlan(uservalue, profilevalue, profileObj, data1)
                })

            }).catch((error) => {
                console.log(error)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
        }
        const timeout = setInterval(() => {
            if (profileLength && profileLength === data1.length) {
                this.updateData(data1)
                clearInterval(timeout)
            }
        }, 200);
    }


    fetchPlan(uservalue, profilevalue, profileObj, data1) {
        queryPlan(uservalue.id, profilevalue.id).then((plan) => {
            plan.forEach(planvalue => {
                if (planvalue.id === "TrainingPlan") {
                    profileObj.PlanCost = planvalue.data().cost
                    profileObj.planName = planvalue.data().name
                    profileObj.planTiming = planvalue.data().timing ? planvalue.data().timing.id : null
                    profileObj.venue = planvalue.data().venue ? planvalue.data().venue.id : null
                    profileObj.planEndDate = planvalue.data().end_date.toDate()
                    profileObj.planStartDate = planvalue.data().start_date.toDate()
                }
            })
            data1.push(profileObj)
        }).catch((error) => {
            console.log(error)
            this.props.enqueueSnackbar('Something went wrong!', {
                variant: 'error'
            });
        })
    }

    updateData(data1) {
        this.setState({ isLoading2: false, students: data1 })
    }

    resetData() {
        this.setState({ isLoading2: false, students: [] })
    }

    submitData() {

        var fields = "";

        if (!this.state.timing) {
            fields += "Time";
        } if (!this.state.venue) {
            fields += (!fields) ? "Time" : " , Venue";
        }

        if (fields) {
            this.props.enqueueSnackbar('Please Select ' + fields + "!", {
                variant: 'warning'
            });
        } else {
            this.setState({ isLoading2: true })
            var profileLength = 0
            var alreadyExists = []
            for (let i = 0; i < this.state.submitLists.length; i++) {
                const uservalue = this.state.submitLists[i];
                profileLength = profileLength + 1
                checkExists(uservalue, this.state.date, this.state.timing, this.state.venue).then((profile) => {
                    profile.forEach(profilevalue => {
                        // data1.push({
                        //     id: profilevalue.id,
                        //     name: profilevalue.data().name,
                        //     category: profilevalue.data().category,
                        //     reg_phone: profilevalue.data().reg_phone,
                        // })
                        // console.log(data1)
                    })

                    if (profile.size === 0) {
                        setAttendance(this.state.venue, this.state.timing, uservalue).then((value) => {
                        }).catch((error) => {
                            console.log(error)
                        })
                    } else {
                        alreadyExists.push(uservalue)
                    }
                    if (profileLength === this.state.submitLists.length) {
                        this.getStudents()
                        this.setState({ alreadyExists }, () => {
                            if (this.state.alreadyExists.length !== 0) {
                                this.setState({ openAlreadyExists: true })
                            }
                        })
                    }
                }).catch((error) => {
                    console.log(error)
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                })

            }


            // const timeout = setInterval(() => {
            //     if (profileLength && profileLength === this.state.submitLists.length) {
            //         this.resetData()
            //         clearInterval(timeout)
            //     }
            // }, 200);
        }
    }

    render() {

        const handleTimingChange = (event) => {
            this.setState({
                timing: event.target.value
            })
        };

        const handleVenueChange = (event) => {
            this.setState({
                venue: event.target.value
            })
        };

        const handleDateChange = (date) => {
            this.setState({
                date: date,
            })
        };

        const handleExistsClose = () => {
            this.setState({
                openAlreadyExists: false,
                alreadyExists: []
            })
        };

        return (
            <div>
                {(this.state.isLoading || this.state.isLoading1) ? <Box display='flex' flexDirection='row' justifyContent={'center'} alignItems='center'><CircularProgress /></Box> :
                    <div>
                        <Box display='flex' flexDirection='row' justifyContent={'center'} alignItems='center'>
                            <Box marginRight={'30px'}>
                                <FormControl style={{ width: '300px' }}>
                                    <InputLabel id="demo-simple-select-label">Venue</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={this.state.venue}
                                        onChange={handleVenueChange}
                                        fullWidth
                                    >
                                        {this.state.venues.map((ele, index) => <MenuItem key={index} value={ele.id}>{ele.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box marginRight={'30px'} >
                                <FormControl style={{ width: '300px' }}>
                                    <InputLabel id="demo-simple-select-label">Timing</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={this.state.timing}
                                        onChange={handleTimingChange}
                                        fullWidth
                                    >
                                        {this.state.timings.map((ele, index) => <MenuItem key={index} value={ele.id}>{ele.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box marginRight={'30px'}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        label="Date"
                                        format="MM/dd/yyyy"
                                        value={this.state.date}
                                        onChange={handleDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        style={{ width: 300 }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Box>
                            <Button variant="contained" color="primary"
                                disabled={this.state.submitLists.length === 0}
                                onClick={() => this.submitData()}
                            >
                                Submit
                            </Button>
                        </Box>
                        <Box>
                            <MaterialTable
                                title="Select Students"
                                columns={[
                                    {
                                        title: 'Name', field: 'name',
                                    },
                                    {
                                        title: 'Venue', field: 'venue', lookup: this.state.lookupVenues
                                    },
                                    {
                                        title: 'Category', field: 'category',
                                    },
                                    {
                                        title: 'Phone', field: 'reg_phone',
                                    },

                                ]}
                                data={this.state.students}
                                isLoading={this.state.isLoading2}
                                style={{ margin: '10px' }}
                                options={{
                                    padding: "dense",
                                    actionsColumnIndex: -1,
                                    selection: true,
                                    paging: false,
                                    filtering: true
                                }}
                                actions={[

                                ]}
                                onSelectionChange={(rows) => this.setState({
                                    submitLists: rows
                                })}
                            />
                        </Box>
                    </div>
                }
                <Dialog open={this.state.openAlreadyExists} TransitionComponent={Transition} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Already Exists</DialogTitle>

                    <DialogContent>
                        <Box >
                            The below mentioned records are already exists for your selected venue, timing and date
                        </Box>
                        <List dense>
                            {
                                this.state.alreadyExists.map((value, i) =>
                                    <ListItemText key={i}>{value.name}</ListItemText>
                                )}
                        </List>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleExistsClose} color="primary">
                            ok
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
        )
    }
}
export default withSnackbar(BookAttendance)



