import React, { Component } from 'react'
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ImageIcon from '@material-ui/icons/Image';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import PaymentIcon from '@material-ui/icons/Payment';
import TwitterIcon from '@material-ui/icons/Twitter';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PeopleIcon from '@material-ui/icons/People';
import { withStyles } from '@material-ui/styles';
import Users from '../Users/Users'
import Payments from '../Payments/Payments';
import Tweet from '../Tweet/Tweet';
import { doc, getDoc } from 'firebase/firestore';
import { getFirestoreDb } from '../CommonThings/FirebaseConfig';
import { deleteCookie, getCookie } from '../CommonThings/CookieHelper';
import BannerMain from '../Banners/BannerMain';
import Venue from '../Venue/Venue';
import StudentLists from '../StudentsList/StudentLists';
import { Tooltip } from '@material-ui/core';
import Timings from '../Timings/Timings';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { Menu, MenuItem } from '@material-ui/core';
import Coaches from '../Coaches/Coaches';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import { getAuth, signOut } from "firebase/auth";
import Attendance from '../Attendance/Attendance';
import BookIcon from '@material-ui/icons/Book';

const auth = JSON.parse(getCookie('access'))

const drawerWidth = 240;

const useStyles = (theme) => ({
    root: {
        display: 'flex',
    },
    grow: {
        flexGrow: 1,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
});

const StyledListItem = withStyles({
    root: {
        "&.Mui-selected": {
            backgroundColor: "#fff5e6",
            color: '#000'
        },
        "&:hover": {
            color: '#000'
        }
    }
})(ListItem);

class Home extends Component {

    constructor(props) {
        super(props)

        this.state = {
            open: false,
            selectedMenu: 'Users',
            authCheck: false,
            anchorEl: null,
        }
    }

    componentDidMount() {
        if (auth) {
            this.getAccess()
        }
    }

    getAccess() {
        queryPlan(auth.phoneNumber).then((value) => {
            console.log(value.data())
            if (!value.data() || !value.data().is_admin) {
                alert("Yor're not an admin")
                // firebase.auth().signOut().then(() => window.location.href = '/')
                // deleteCookie('access')

            } else {
                this.setState({
                    authCheck: true
                })
            }
        }).catch((error) => {
            alert(error)
        })
    }

    signOut() {
        const auth = getAuth();
        signOut(auth).then(() => {
            this.setState({ anchorEl: null })
            deleteCookie('access')
            // Sign-out successful.
        }).catch((error) => {
            alert('something went wrong')
        });
    }

    render() {
        const { classes } = this.props;
        const handleDrawerOpen = () => {
            this.setState({ open: true })
        };

        const handleDrawerClose = () => {
            this.setState({ open: false })
        };

        const handleViewChange = (menu) => {
            this.setState({ selectedMenu: menu })
        };
        const handleClose = () => {
            this.setState({ anchorEl: null })
        };

        const handleAvatarClick = (event) => {
            this.setState({ anchorEl: event.currentTarget })
        };

        return (
            this.state.authCheck ?
                <div className={classes.root}>
                    <CssBaseline />
                    <AppBar
                        position="fixed"
                        className={clsx(classes.appBar, {
                            [classes.appBarShift]: this.state.open,
                        })}
                    >
                        <Toolbar>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                className={clsx(classes.menuButton, {
                                    [classes.hide]: this.state.open,
                                })}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography variant="h6" noWrap>
                                YuVe Champions Academy
                            </Typography>
                            <div className={classes.grow} />
                            <div className={classes.sectionDesktop}>
                                <IconButton
                                    edge="end"
                                    aria-label="account of current user"
                                    aria-haspopup="true"
                                    onClick={handleAvatarClick}
                                    color="inherit"
                                >
                                    <AccountCircle />
                                </IconButton>
                            </div>
                            <Menu
                                id="simple-menu"
                                anchorEl={this.state.anchorEl}
                                keepMounted
                                open={Boolean(this.state.anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem id="signout" onClick={() => this.signOut()}>Sign Out</MenuItem>
                            </Menu>
                        </Toolbar>
                    </AppBar>
                    <Drawer
                        variant="permanent"
                        className={clsx(classes.drawer, {
                            [classes.drawerOpen]: this.state.open,
                            [classes.drawerClose]: !this.state.open,
                        })}
                        classes={{
                            paper: clsx({
                                [classes.drawerOpen]: this.state.open,
                                [classes.drawerClose]: !this.state.open,
                            }),
                        }}
                    >
                        <div className={classes.toolbar}>
                            <IconButton onClick={handleDrawerClose}>
                                {<ChevronRightIcon />}
                            </IconButton>
                        </div>
                        <Divider />
                        <List>
                            {['Users', 'Payments', 'Tweet', 'Venue', 'Banners', 'Students List', 'Timings', 'Coach List', 'Attendance'].map((text, index) => (
                                <Tooltip title={text} aria-label={text} key={text}>
                                    <StyledListItem button key={text} onClick={e => handleViewChange(text)} selected={text === this.state.selectedMenu}>
                                        <ListItemIcon>{index === 0 ? <GroupAddIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : index === 1 ? <PaymentIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : index === 2 ? <TwitterIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : index === 3 ? <LocationOnIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : index === 4 ? <ImageIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : index === 5 ? <PeopleIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : index === 6 ? <AccessTimeIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : index === 7 ? <DirectionsWalkIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : index === 8 ? <BookIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : null}</ListItemIcon>
                                        <ListItemText primary={text} />
                                    </StyledListItem>
                                </Tooltip>
                            ))}
                        </List>
                    </Drawer>
                    <main className={classes.content}>
                        <div className={classes.toolbar} />
                        {this.state.selectedMenu === 'Users' ? <Users /> :
                            this.state.selectedMenu === 'Payments' ? <Payments /> :
                                this.state.selectedMenu === 'Tweet' ? <Tweet /> :
                                    this.state.selectedMenu === 'Banners' ? <BannerMain /> :
                                        this.state.selectedMenu === 'Venue' ? <Venue /> :
                                            this.state.selectedMenu === 'Students List' ? <StudentLists /> :
                                                this.state.selectedMenu === 'Timings' ? <Timings /> :
                                                    this.state.selectedMenu === 'Coach List' ? <Coaches /> :
                                                        this.state.selectedMenu === 'Attendance' ? <Attendance /> : null}
                    </main>
                </div>

                : null

        )
    }
}

async function queryPlan(phone) {
    if (auth) {
        const db = getFirestoreDb();
        const querySnapshot = await getDoc(doc(db, "Users", phone));
        return querySnapshot
    }
}

export default withStyles(useStyles)(Home)