import React, { Component } from 'react'
import { getDocs, collection, setDoc, doc } from "firebase/firestore";
import { getFirestoreDb } from '../CommonThings/FirebaseConfig';
import { getCookie } from '../CommonThings/CookieHelper';
import MaterialTable from 'material-table';
import Profiles from './Profiles';
import { withSnackbar } from 'notistack';

const auth = JSON.parse(getCookie('access'))
class Users extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            data: [],
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData() {
        queryUsers().then((value) => {
            var data = []
            value.forEach(element => {
                data.push({
                    id: element.id,
                    active: element.data().active
                })
            })
            this.setState({
                isLoading: false,
                data,
            })
        }).catch(() => {
            this.props.enqueueSnackbar('Something went wrong!', {
                variant: 'error'
            });
        })
    }

    postOps(newData) {
        setUser(newData.id).then((value) => {
            this.fetchData()
            this.props.enqueueSnackbar('User Added Successfully!', {
                variant: 'success'
            });
        }).catch(() => {
            this.props.enqueueSnackbar('Something went wrong!', {
                variant: 'error'
            });
        })
    }

    switchActive(id, active) {
        switchActive(id, active).then((value) => {
            this.props.enqueueSnackbar('Status Updated Successfully!', {
                variant: 'success'
            });
            this.fetchData()
        }).catch(() => {
            this.props.enqueueSnackbar('Something went wrong!', {
                variant: 'error'
            });
        })
    }

    render() {
        return (
            <div>
                <MaterialTable
                    title="Accounts"
                    columns={[
                        { title: 'Phone Number', field: 'id' },
                        { title: 'Active', field: 'active' },

                    ]}
                    isLoading={this.state.isLoading}
                    data={this.state.data}
                    detailPanel={rowData => {
                        return (
                            <Profiles phone={rowData.id} />
                        )
                    }}
                    options={{
                        paging: false
                    }}
                    actions={[
                        rowData => ({
                            icon: 'play_circle_filled',
                            tooltip: 'Active/Inactive',
                            onClick: (event, rowData) => this.switchActive(rowData.id, rowData.active),
                        }),
                    ]}
                    editable={{
                        onRowAdd: newData =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    this.setState(prevState => {
                                        const data = [...prevState.data];
                                        this.postOps(newData);
                                        data.push(newData);
                                        return { ...prevState, data };
                                    });
                                }, 600);
                            }),
                    }}
                />
            </div>
        )
    }
}

export default withSnackbar(Users)

async function queryUsers() {
    if (auth) {
        const db = getFirestoreDb();
        const querySnapshot = await getDocs(collection(db, "Users"));
        return querySnapshot
    }
}

async function setUser(phone) {
    const db = getFirestoreDb();
    await setDoc(doc(db, "Users", phone), {
        active: true
    });
}

async function switchActive(phone, active) {
    const db = getFirestoreDb();
    await setDoc(doc(db, "Users", phone), {
        active: !active
    });
}