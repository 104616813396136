// import logo from './logo.svg';
import './App.css';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Home from './Home/Home';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { getFirestore } from "firebase/firestore"
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { firebaseConfig, setAuth, setFirebase, setFirestoreDb, setUser } from './CommonThings/FirebaseConfig';
import Login from './Login/Login';
import { getCookie, setCookie } from './CommonThings/CookieHelper';
import React, { Component } from 'react'
import { SnackbarProvider } from 'notistack';

export default class App extends Component {

  constructor(props) {
    super(props)

    this.state = {
      isLoading: true
    }
  }


  componentDidMount() {
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    console.log(analytics)
    const db = getFirestore()
    setFirestoreDb(db)
    setFirebase(app)
    this.setState({ isLoading: false })
    const auth = getAuth(app);
    setAuth(auth)
    if (getCookie('access')) {
      if (!window.location.href.includes("home")) {
        window.location.href = "/home"
      }

    }
    onAuthStateChanged(auth, user => {
      if (user) {
        setUser(user)
        setCookie('access', JSON.stringify(user), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', });
      } else {
        if (!window.location.href.includes("/login")) {
          window.location.href = "/login"
        }

      }
    });
  }

  render() {
    return (
      <>
        {(this.state.isLoading) ? (
          null
        ) : (
          <SnackbarProvider maxSnack={3}>
            <BrowserRouter basename="/" >
              <Switch>
                <Route exact path="/home" component={Home} />
                <Route exact path="/login" component={Login} />
              </Switch>
            </BrowserRouter>
          </SnackbarProvider>
        )}
      </>
    );
  }
}


