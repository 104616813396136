var CryptoJS = require("crypto-js");

export function encryptData(data) {
    return CryptoJS.AES.encrypt(data, 'Q424n6KiLD2UXj1wb0UxK9').toString();
}

export function setCookie(name, value, options = {}) {

    if (window.location.hostname === 'localhost' || window.location.protocol === 'http:') {
        localStorage.setItem(name, value);
    } else {
        localStorage.removeItem(name);
        var ciphertext = CryptoJS.AES.encrypt(value, 'Q424n6KiLD2UXj1wb0UxK9').toString();
        // console.log(ciphertext)

        options = {
            path: '/',
            // add other defaults here if necessary
            ...options
        };

        if (options.expires instanceof Date) {
            options.expires = options.expires.toUTCString();
        }

        let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(ciphertext);

        for (let optionKey in options) {
            updatedCookie += "; " + optionKey;
            let optionValue = options[optionKey];
            if (optionValue !== true) {
                updatedCookie += "=" + optionValue;
            }
        }

        document.cookie = updatedCookie;
    }
}

export function deleteCookie(name) {
    // alert(name)
    if (window.location.hostname === 'localhost' || window.location.protocol === 'http:') {
        localStorage.removeItem(name);
    } else {
        setCookie(name, "", { secure: true, 'max-age': -604800, 'domain': window.location.host, 'samesite': 'strict', 'path': '/', },)
    }
}

export function getCookie(name) {
    if (window.location.hostname === 'localhost' || window.location.protocol === 'http:') {
        return localStorage.getItem(name);
    } else {
        /* eslint-disable-next-line */
        let matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));

        var value = matches ? decodeURIComponent(matches[1]) : undefined;
        if (value) {
            try {
                var bytes = CryptoJS.AES.decrypt(value, 'Q424n6KiLD2UXj1wb0UxK9');
                var originalText = bytes.toString(CryptoJS.enc.Utf8);
                if (originalText === "") {
                    return null;
                } else {
                    return originalText;
                }
                
            } catch (error) {
                return null;
            }
        } else {
            return null;
        }
    }

}