import { useState } from 'react';
import storage from './firebase';
import { ref, uploadBytes } from "firebase/storage";
function BillUpload(props) {
    const [image, setImage] = useState('');
    const upload = () => {
        if (image == null)
            return;
        console.log(image.name.split('.').pop())

        if (image.name.split('.').pop() === 'pdf') {
            const storageRef = ref(storage,  "payments/invoices/" + props.data.id + ".pdf");
            uploadBytes(storageRef, image).then((snapshot) => {
                alert('Uploaded a blob or file!');
            });
        } else {
            alert("Only PDF is allowded")
        }


    }

    return (
        <div>
            <center>
                <input type="file" onChange={(e) => { setImage(e.target.files[0]) }} />
                <button onClick={upload}>Upload</button>
            </center>
        </div>
    );
}

export default BillUpload;