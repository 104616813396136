import { deleteDoc, doc } from 'firebase/firestore'
import MaterialTable from 'material-table'
import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import { queryCoaches, setCoach, updateCoach } from '../CommonThings/ApiCall'
import { getFirestoreDb } from '../CommonThings/FirebaseConfig'

class Coaches extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
            isLoading: false
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData() {
        queryCoaches().then((value) => {
            var data = []
            value.forEach(element => {
                data.push({
                    id: element.id,
                    name: element.data().name,
                    phone: element.data().phone,
                })
            })
            this.setState({
                isLoading: false,
                data: data,
            })
        }).catch((e) => {
            console.log(e)
            this.props.enqueueSnackbar('Something went wrong!', {
                variant: 'error'
            });
        })
    }

    postOps(newData) {
        if (newData.name && newData.phone) {
            setCoach(newData).then((value) => {
                this.fetchData()
                this.props.enqueueSnackbar('Added Successfully!', {
                    variant: 'success'
                });
            }).catch((error) => {
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
                console.log(error)
            })
        } else {
            this.fetchData()
            this.props.enqueueSnackbar('Fill all!', {
                variant: 'warning'
            });
        }
    }

    putOps(newData) {
        if (newData.id && newData.name && newData.phone) {
            updateCoach(newData).then((value) => {
                this.fetchData()
                this.props.enqueueSnackbar('Updated Successfully!', {
                    variant: 'success'
                });
            }).catch((error) => {
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
                console.log(error)
            })
        } else {
            this.fetchData()
            this.props.enqueueSnackbar('Fill all!', {
                variant: 'warning'
            });
        }
    }

    deleteCoach(data) {
        const db = getFirestoreDb();
        deleteDoc(doc(db, "Coaches/", data.id)).then((value) => {
            this.fetchData()
            this.props.enqueueSnackbar('Deleted Successfully!', {
                variant: 'success'
            });
        }).catch((error) => {
            this.setState({ isLoading: false })
            this.fetchData()
            this.props.enqueueSnackbar('Something went wrong!', {
                variant: 'error'
            });
        })
    }

    render() {
        return (
            <div>
                <MaterialTable
                    title="Coach List"
                    columns={[
                        { title: 'Name', field: 'name' },
                        { title: 'Phone Number', field: 'phone' },

                    ]}
                    isLoading={this.state.isLoading}
                    data={this.state.data}
                    options={{
                        paging: false,
                        filtering: true,
                        actionsColumnIndex: -1
                    }}
                    editable={{
                        onRowAdd: newData =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    this.setState(prevState => {
                                        const data = [...prevState.data];
                                        data.push(newData);
                                        return { ...prevState, data };
                                    });
                                    this.postOps(newData);
                                }, 600);
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    if (oldData) {
                                        this.setState(prevState => {
                                            const data = [...prevState.data];
                                            data[data.indexOf(oldData)] = newData;
                                            return { ...prevState, data };
                                        });
                                        this.putOps(newData);
                                    }
                                }, 600);
                            }),
                        onRowDelete: oldData =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    this.setState(prevState => {
                                        const data = [...prevState.data];
                                        data.splice(data.indexOf(oldData), 1);
                                        return { ...prevState, data };
                                    });
                                    this.deleteCoach(oldData)
                                }, 600);
                            }),
                    }}
                />
            </div>
        )
    }
}
export default withSnackbar(Coaches)