import { getDocs, collection, setDoc, doc, addDoc, query, where } from "firebase/firestore";
import moment from "moment";
import { getFirestoreDb } from '../CommonThings/FirebaseConfig';
import { getCookie } from "./CookieHelper";

const auth = JSON.parse(getCookie('access'))

export async function queryVenue() {
    if (auth) {
        const db = getFirestoreDb();
        const querySnapshot = await getDocs(collection(db, 'Venues'));
        return querySnapshot
    }
}
export async function queryProfiles(phone) {
    if (auth) {
        const db = getFirestoreDb();
        const querySnapshot = await getDocs(collection(db, "Users/" + phone + "/profiles"));
        return querySnapshot
    }
}

export async function queryStudents() {
    if (auth) {
        const db = getFirestoreDb();
        const querySnapshot = await getDocs(collection(db, "Users/"));
        return querySnapshot
    }
}

export async function queryPlan(phone, id) {
    if (auth) {
        const db = getFirestoreDb();
        const querySnapshot = await getDocs(collection(db, "Users/" + phone + "/profiles/" + id + "/ActivePlan"));
        return querySnapshot
    }
}

export async function setPlan(phone, profileId, name, decs, start_date, end_date, plan_type, payment_status, cost, venue) {
    const db = getFirestoreDb();
    await setDoc(doc(db, "Users/" + phone + "/profiles/" + profileId + "/ActivePlan", "TrainingPlan"), {
        name: name,
        decs: decs,
        active: true,
        start_date: start_date,
        end_date: end_date,
        plan_type: plan_type,
        payment_status: payment_status,
        cost: cost,
        venue: doc(db, 'Venues/' + venue)
    });
}

export async function setProfile(phone, name, addr, dob, category) {
    const db = getFirestoreDb();
    await addDoc(collection(db, "Users/" + phone + "/profiles"), {
        name: name,
        address: addr,
        dob: dob,
        category: category,
        phno: phone
    });
}

export async function setAttendance(venue, timing, data) {
    const db = getFirestoreDb();

    await addDoc(collection(db, "Attendance"), {
        name: doc(db, 'Users/' + data.reg_phone + '/profiles/' + data.id),
        venue: doc(db, 'Venues/' + venue),
        timing: doc(db, 'Timing/' + timing),
        date_created: moment().format('DD-MM-YYYY')
    });
}

export async function setTiming(name) {
    const db = getFirestoreDb();
    await addDoc(collection(db, "Timing"), {
        name: name,
        timestamp: new Date()
    });
}

export async function queryTimings() {
    if (auth) {
        const db = getFirestoreDb();
        const querySnapshot = await getDocs(collection(db, "Timing"));
        return querySnapshot
    }
}

export async function updateTiming(id, name) {
    const db = getFirestoreDb();
    await setDoc(doc(db, "Timing/", id), {
        name: name,
        timestamp: new Date()
    });
}

export async function setPayments(cost, payment_method, propsData) {
    const db = getFirestoreDb();
    await addDoc(collection(db, "Invoices"), {
        phone_number: propsData.reg_phone,
        cost: cost,
        payment_method: payment_method ? payment_method : 'Online',
        date_created: new Date(),
        plan_name: propsData.planName,
        profile_id: propsData.id,
        profile_name: propsData.name
    });
}

export async function queryCoaches() {
    if (auth) {
        const db = getFirestoreDb();
        const querySnapshot = await getDocs(collection(db, "Coaches"));
        return querySnapshot
    }
}

export async function setCoach(data) {
    const db = getFirestoreDb();
    await addDoc(collection(db, "Coaches"), {
        name: data.name,
        phone: data.phone
    });
}

export async function updateCoach(data) {
    const db = getFirestoreDb();
    await setDoc(doc(db, "Coaches/", data.id), {
        name: data.name,
        phone: data.phone
    });
}

export async function checkExists(data, date, timing, venue) {
    const db = getFirestoreDb();
    const student = doc(db, "Users/" + data.reg_phone + "/profiles/" + data.id)
    const Timing = doc(db, "Timing", timing);
    const Venue = doc(db, "Venues", venue);
    const q = query(collection(db, "Attendance"), where("date_created", "==", moment(date).format('DD-MM-YYYY')), where("name", "==", student), where("timing", "==", Timing), where("venue", "==", Venue));
    const querySnapshot = await getDocs(q);
    return querySnapshot
}

export async function DTVExists(date, timing, venue) {
    const db = getFirestoreDb();
    const Timing = doc(db, "Timing", timing);
    const Venue = doc(db, "Venues", venue);
    const q = query(collection(db, "Attendance"), where("date_created", "==", moment(date).format('DD-MM-YYYY')), where("timing", "==", Timing), where("venue", "==", Venue));
    const querySnapshot = await getDocs(q);
    return querySnapshot
}

export async function STVExists(data, timing, venue) {
    const db = getFirestoreDb();
    const student = doc(db, "Users/" + data.reg_phone + "/profiles/" + data.id)
    const Timing = doc(db, "Timing", timing);
    const Venue = doc(db, "Venues", venue);
    const q = query(collection(db, "Attendance"), where("name", "==", student), where("timing", "==", Timing), where("venue", "==", Venue));
    const querySnapshot = await getDocs(q);
    return querySnapshot
}

export async function SVExists(data, venue) {
    const db = getFirestoreDb();
    const student = doc(db, "Users/" + data.reg_phone + "/profiles/" + data.id)
    const Venue = doc(db, "Venues", venue);
    const q = query(collection(db, "Attendance"), where("name", "==", student), where("venue", "==", Venue));
    const querySnapshot = await getDocs(q);
    return querySnapshot
}

export async function VDExists( date,  venue) {
    const db = getFirestoreDb();
    const Venue = doc(db, "Venues", venue);
    const q = query(collection(db, "Attendance"), where("date_created", "==", moment(date).format('DD-MM-YYYY')), where("venue", "==", Venue));
    const querySnapshot = await getDocs(q);
    return querySnapshot
}
