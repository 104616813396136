import React, { Component } from 'react'
import { getDocs, collection, setDoc, doc, addDoc } from "firebase/firestore";
import { getFirestoreDb } from '../CommonThings/FirebaseConfig';
import { getCookie } from '../CommonThings/CookieHelper';
import MaterialTable from 'material-table';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DateFnsUtils from '@date-io/date-fns';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { Box } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { queryTimings, queryVenue } from '../CommonThings/ApiCall';

const auth = JSON.parse(getCookie('access'))
class Profiles extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            data: [],
            openSub: false,
            planStartDate: new Date(),
            planEndDate: new Date(),
            planType: 0,
            paymentStatus: 0,
            selectedProfile: null,
            planName: "",
            PlanCost: 0,
            planDescription: '',
            profileName: "",
            profileAddress: "",
            profileCategory: "",
            profileDob: new Date(),
            openProfileAdd: false,
            venue: null,
            venues: [],
            timings: []
        }
    }


    componentDidMount() {
        this.fetchData()
        queryStudents().then((value) => {
            value.forEach(element => {
                console.log(element.data())
            })
        })
    }

    fetchData() {
        queryProfiles(this.props.phone).then((value) => {
            var data = []
            value.forEach(element => {
                var dob = null
                try {
                    dob = element.data().dob.toDate()
                } catch (error) {
                    dob = element.data().dob
                }
                data.push({
                    id: element.id,
                    name: element.data().name,
                    address: element.data().address,
                    contact1: element.data().contact1,
                    contact2: element.data().contact2,
                    dob: dob,
                    joining_reason: element.data().joining_reason,
                    category: element.data().category,
                    phno: element.data().phno
                })
            })
            this.setState({
                isLoading: false,
                data,
            })
        }).catch((error) => {
            alert(error)
        })

        queryVenue().then((value) => {
            var venues = []
            value.forEach(element => {
                venues.push({
                    id: element.id,
                    name: element.data().name,
                })
            })
            this.setState({
                venues: venues,
            })
        }).catch((error) => {
            alert(error)
        })

        queryTimings().then((value) => {
            var timings = []
            value.forEach(element => {
                timings.push({
                    id: element.id,
                    name: element.data().name,
                    date_created: element.data().timestamp,
                })
            })
            this.setState({
                isLoading: false,
                timings: timings,
            })
        }).catch((e) => {
            console.log(e)
            alert("Something went wrong")
        })
    }

    postData() {
        if (this.props.phone && this.state.selectedProfile && this.state.planName && this.state.planStartDate && this.state.planEndDate && this.state.PlanCost && this.state.venue) {
            setPlan(this.props.phone, this.state.selectedProfile.id, this.state.planName, this.state.planDescription, this.state.planStartDate, this.state.planEndDate, this.state.planType, this.state.paymentStatus, this.state.PlanCost, this.state.venue).then((value) => {
                this.setState({
                    openSub: false,
                    planName: "",
                    planDescription: '',
                    planStartDate: new Date(),
                    planEndDate: new Date(),
                    planType: 0,
                    PlanCost: 0,
                })
                this.props.enqueueSnackbar('Subscription Done Successfully!', {
                    variant: 'success'
                });
            }).catch((error) => {
                alert(error)
            })
        } else {
            this.props.enqueueSnackbar('Fill all the details!', {
                variant: 'warning'
            });
        }

    }

    getPlan(profile) {
        // const db = getFirestoreDb();
        queryPlan(this.props.phone, profile.id).then((value) => {
            // var data = []
            if (value.size === 0) {
                this.setState({
                    isLoading: false,
                    openSub: true
                })
            }
            value.forEach(element => {
                if (element.id === "TrainingPlan") {
                    this.setState({
                        planName: element.data().timing ? element.data().timing.id : null,
                        planDescription: element.data().decs,
                        planStartDate: element.data().start_date.toDate(),
                        planEndDate: element.data().end_date.toDate(),
                        planType: element.data().plan_type,
                        PlanCost: parseInt(element.data().cost),
                        isLoading: false,
                        openSub: true,
                        venue: element.data().venue ? element.data().venue.id : null,
                    })
                }
            })

        }).catch((error) => {
            alert(error)
        })
    }

    postProfile() {
        if (this.state.profileName && this.state.profileAddress && this.state.profileCategory) {
            setProfile(this.props.phone, this.state.profileName, this.state.profileAddress, this.state.profileDob, this.state.profileCategory).then((value) => {
                this.fetchData()
                this.setState({ openProfileAdd: false })
                this.props.enqueueSnackbar('Profile Added Successfully!', {
                    variant: 'success'
                });
            }).catch((error) => {
                this.setState({ openProfileAdd: false })
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
                alert(error)
            })
        } else {
            this.props.enqueueSnackbar('Fill all the details!', {
                variant: 'warning'
            });
        }
    }

    // deleteProfile(profile) {

    //     queryPlan(this.props.phone, profile).then((value) => {

    //         value.forEach(element => {
    //             element.delete()
    //         })

    //     }).catch((error) => {
    //         alert(error)
    //     })

    //     const db = getFirestoreDb();
    //     deleteDoc(doc(db, "Users/" + this.props.phone + "/profiles/" + profile)).then((value) => {
    //         // alert("deleted")
    //         this.fetchData()
    //     }).catch((error) => {
    //         alert(error)
    //     })
    // }

    render() {

        const handleClose = () => {
            this.setState({
                openSub: false,
                planName: "",
                planDescription: '',
                planStartDate: new Date(),
                planEndDate: new Date(),
                planType: 0,
                PlanCost: 0,
            })
        };

        const handleProfileClose = () => {
            this.setState({
                openProfileAdd: false,
            })
        };
        const handleDateChange = (date) => {
            this.setState({
                planStartDate: date,
                planEndDate: date
            })
        };

        const handleDate2Change = (date) => {
            this.setState({
                planEndDate: date
            })
        };
        const handleProfiledobChange = (date) => {
            this.setState({
                profileDob: date,

            })
        };

        const handlePlanTypeChange = (event) => {
            this.setState({
                planType: event.target.value
            })
        };

        const handleVenueChange = (event) => {
            this.setState({
                venue: event.target.value
            })
        };

        // const handlePaymentTypeChange = (event) => {
        //     this.setState({
        //         paymentStatus: event.target.value
        //     })
        // };
        const handlePlanNameChange = (event) => {
            this.setState({
                planName: event.target.value
            })
        };
        const handleProfileNameChange = (event) => {
            this.setState({
                profileName: event.target.value
            })
        };
        const handleProfileAddressChange = (event) => {
            this.setState({
                profileAddress: event.target.value
            })
        };

        const handlePlanDecsChange = (event) => {
            this.setState({
                planDescription: event.target.value
            })
        };

        const handlePlanCostChange = (event) => {
            this.setState({
                PlanCost: event.target.value
            })
        };

        const handleProfileCategoryChange = (event) => {
            this.setState({ profileCategory: event.target.value })
        }

        return (
            <div>
                <MaterialTable
                    title="Profile"
                    columns={[
                        { title: 'Profile Id', field: 'id' },
                        { title: 'Name', field: 'name' },
                        { title: 'Category', field: 'category', lookup: { 0: 'Kids', 1: 'Adults', 2: 'Womens' } },
                        { title: 'Address', field: 'address' },
                        { title: 'Contact', field: 'phno' },
                        { title: 'DOB', field: 'dob', type: 'date' },
                        { title: 'Joining Reason', field: 'joining_reason' },
                    ]}
                    isLoading={this.state.isLoading}
                    data={this.state.data}
                    options={{
                        paging: false
                    }}
                    actions={[
                        {
                            icon: 'subscriptions',
                            tooltip: 'Subcription',
                            onClick: (event, rowData) => {
                                this.getPlan(rowData)
                                this.setState({ selectedProfile: rowData, isLoading: true })
                            }
                        },
                        // {
                        //     icon: 'delete',
                        //     tooltip: 'Delete',
                        //     onClick: (event, rowData) => {
                        //         this.deleteProfile(rowData)
                        //         this.setState({ selectedProfile: rowData, isLoading: true })
                        //     }
                        // },
                        {
                            icon: 'add',
                            tooltip: 'Add User',
                            isFreeAction: true,
                            // disabled: this.state.data.length > 0,
                            onClick: (event) => this.setState({ openProfileAdd: true })
                        }
                    ]}
                // detailPanel={rowData => {
                //     return (
                //         <Profiles phone={rowData.id} />
                //     )
                // }}
                />
                <Dialog open={this.state.openSub} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please enter the subscription information carefully
                        </DialogContentText>
                        <Box display={'flex'} flexDirection='column'>
                            <FormControl style={{ width: '100%' }}>
                                <InputLabel id="demo-simple-select-label">Plan Name</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={this.state.planName}
                                    onChange={handlePlanNameChange}
                                    fullWidth
                                >
                                    {this.state.timings.map((ele, index) => <MenuItem key={index} value={ele.id}>{ele.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                            <Box mt={1}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="decs"
                                    label="Description"
                                    value={this.state.planDescription}
                                    onChange={handlePlanDecsChange}
                                    fullWidth
                                />
                            </Box>
                            <Box display={'flex'} flexDirection='row' mt={1} justifyContent='space-around'>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        label="Start Date"
                                        format="MM/dd/yyyy"
                                        value={this.state.planStartDate}
                                        onChange={handleDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        style={{ width: 225 }}
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        label="End Date"
                                        format="MM/dd/yyyy"
                                        value={this.state.planEndDate}
                                        onChange={handleDate2Change}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        style={{ width: 225 }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Box>
                            <Box display={'flex'} flexDirection='row' mt={1} justifyContent='space-around'>
                                <FormControl style={{ minWidth: '150px' }}>
                                    <InputLabel id="demo-simple-select-label">Plan Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={this.state.planType}
                                        onChange={handlePlanTypeChange}
                                        autoWidth
                                    >
                                        <MenuItem value={0}>Monthly</MenuItem>
                                        <MenuItem value={1}>Periodic</MenuItem>
                                    </Select>
                                </FormControl>

                                <FormControl style={{ minWidth: '150px' }}>
                                    <InputLabel id="demo-simple-select-label1">Venue</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label1"
                                        id="demo-simple-select1"
                                        autoWidth
                                        value={this.state.venue}
                                        onChange={handleVenueChange}
                                    >
                                        {this.state.venues.map((ele, index) => <MenuItem key={index} value={ele.id}>{ele.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box mt={1}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="cost"
                                    label="Plan Cost"
                                    type="number"
                                    value={this.state.PlanCost}
                                    onChange={handlePlanCostChange}
                                    fullWidth
                                />
                            </Box>
                        </Box>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => this.postData()} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.openProfileAdd} onClose={handleProfileClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Add</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please enter the informations correctly
                        </DialogContentText>
                        <Box display='flex' flexDirection='column'>
                            <Box width='100%'>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    label="Name"
                                    value={this.state.profileName}
                                    onChange={handleProfileNameChange}
                                    fullWidth
                                />
                            </Box>

                            <FormControl style={{ width: '100%', marginTop: '5px' }}>
                                <InputLabel id="demo-simple-select-label">Category</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={this.state.profileCategory}
                                    onChange={handleProfileCategoryChange}
                                    fullWidth
                                >
                                    <MenuItem value={0}>Kids</MenuItem>
                                    <MenuItem value={1}>Adults</MenuItem>
                                    <MenuItem value={2}>Womens</MenuItem>
                                </Select>
                            </FormControl>

                            <Box width='100%' mt={1}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="decs"
                                    label="Address"
                                    value={this.state.profileAddress}
                                    onChange={handleProfileAddressChange}
                                    fullWidth
                                />
                            </Box>

                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label="Start Date"
                                    format="MM/dd/yyyy"
                                    value={this.state.profileDob}
                                    onChange={handleProfiledobChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleProfileClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => this.postProfile()} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </div >
        )
    }
}
export default withSnackbar(Profiles)

async function queryProfiles(phone) {
    if (auth) {
        const db = getFirestoreDb();
        const querySnapshot = await getDocs(collection(db, "Users/" + phone + "/profiles"));
        return querySnapshot
    }
}

async function queryStudents() {
    if (auth) {
        const db = getFirestoreDb();
        const querySnapshot = await getDocs(collection(db, "Users/"));
        return querySnapshot
    }
}

async function queryPlan(phone, id) {
    if (auth) {
        const db = getFirestoreDb();
        const querySnapshot = await getDocs(collection(db, "Users/" + phone + "/profiles/" + id + "/ActivePlan"));
        return querySnapshot
    }
}

async function setPlan(phone, profileId, name, decs, start_date, end_date, plan_type, payment_status, cost, venue) {
    const db = getFirestoreDb();
    await setDoc(doc(db, "Users/" + phone + "/profiles/" + profileId + "/ActivePlan", "TrainingPlan"), {
        name: "",
        decs: decs,
        active: true,
        start_date: start_date,
        end_date: end_date,
        plan_type: plan_type,
        payment_status: payment_status,
        cost: cost,
        venue: doc(db, 'Venues/' + venue),
        timing: doc(db, 'Timing/' + name)
    });
}

async function setProfile(phone, name, addr, dob, category) {
    const db = getFirestoreDb();
    await addDoc(collection(db, "Users/" + phone + "/profiles"), {
        name: name,
        address: addr,
        dob: dob,
        category: category,
        phno: phone
    });
}